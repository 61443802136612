<template>
  <van-tabbar v-model="active" @change="clickTab">
    <van-tabbar-item>
      <span>活动</span>
      <template #icon="props">
        <img :src="props.active ? home1 : home2" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item>
      <span>推文</span>
      <template #icon="props">
        <img :src="props.active ? tweet1 : tweet2" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item icon="friends-o">
      <span>我的</span>
      <template #icon="props">
        <img :src="props.active ? mine1 : mine2" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      paths: ['/', '/tweet', '/mine'],
      home1: require("@/assets/navbar/home.png"),
      home2: require("@/assets/navbar/home2.png"),
      tweet1: require("@/assets/navbar/tweet.png"),
      tweet2: require("@/assets/navbar/tweet2.png"),
      mine1: require("@/assets/navbar/mine.png"),
      mine2: require("@/assets/navbar/mine2.png"),
    };
  },
  methods: {
    clickTab(tab) {
      this.$router.push(this.paths[tab])
    }
  },
  mounted() {
    for (let i in this.paths) {
      if (this.paths[i] === this.$route.path) {
        this.active = Number(i)
        break;
      }
    }
  }
};
</script>